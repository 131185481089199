import React, { useState, useEffect, useMemo } from "react";

import { useSettings } from '_hooks';

import { message, Tabs } from "antd";
import { useNavigate } from "react-router-dom";

import { SettingsIrrecoverabilityCertificates } from "./_SettingsIrrecoverabilityCertificates";
import { SettingsSendingPreferences } from "./_SettingsSendingPreferences";

export const Settings = () => {
    const [ activeKey, setActiveKey ] = useState('sending');

    const [ doRedirect, setDoRedirect ] = useState(null);

    const [ alertIsPending, setAlertIsPending ] = useState(false);
    const alertIsPendingHandler = { alertIsPending, setAlertIsPending };

    const { valuesHandler, irrecCerts, setRequestIsSubmitted, settingsDoRedirect, messageHandler } = useSettings();
    const { successMessage, setSuccessMessage, errorMessage, setErrorMessage } = messageHandler;

    const [ messageApi, contextHolder ] = message.useMessage();

    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Espace client - Paramètres';
    }, [])

    useEffect(() => {
        if( settingsDoRedirect ) navigate('/login');
        
        const timer = setTimeout(() => { // Permet un affichage retardé en fonction du retour de la requête
            setDoRedirect(settingsDoRedirect);
        }, 150)

        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsDoRedirect])

    useEffect(() => {
        if( successMessage || errorMessage ){
            if( successMessage ){
                messageApi.success(successMessage);
                setSuccessMessage(null);
            } else if( errorMessage ){
                messageApi.error(errorMessage);
                setErrorMessage(null);
            }
            setRequestIsSubmitted(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMessage, errorMessage])

    useEffect(() => {
        const handleBeforeUnload = (e) => {
          // Perform actions before the component unloads
          e.preventDefault();
          e.returnValue = '';
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, []);

    const tabsItems = useMemo(() => ([
        {
            key: 'gen',
            label: 'Général',
            hidden: true
        },{
            key: 'sending',
            label: 'Préférences d\'envoi',
            children: (
                <SettingsSendingPreferences 
                    valuesHandler={valuesHandler} 
                    alertIsPendingHandler={alertIsPendingHandler} 
                    setRequestIsSubmitted={setRequestIsSubmitted} 
                    activeKey={activeKey} 
                />
            )
        },{
            key: 'irrec',
            label: 'Mes certificats d\'irrécouvrabilité',
            children: (
                <SettingsIrrecoverabilityCertificates 
                    irrecCerts={irrecCerts} 
                />
            )
        }
    ]
        .filter(el => !el.hidden)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [valuesHandler?.values, alertIsPendingHandler['alertIsPending'], activeKey, irrecCerts])

    const handleTabChange = ( newKey ) => {
        if( alertIsPending ) return ;
        // Check if values change and display alert message before change tab
        setActiveKey(newKey);
    }

    return (
        <>
            { 
                doRedirect !== null && 
                    <div style={{ height: 'calc(100% - 50px)', marginTop: '20px' }}>
                        { contextHolder }
                        <Tabs 
                            items={tabsItems} 
                            tabPosition="left" 
                            activeKey={activeKey}
                            className="h-100"
                            onChange={(key) => handleTabChange(key)}
                        />
                    </div>
            }
        </>
    );
}