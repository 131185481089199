import React, { useState, useMemo, useCallback, useEffect } from "react";

import { InputByType as Input } from "_components";
import { useApp } from "_contexts";
import { 
    arraysAreEquals, 
    deepCopy,
    isNonEmpty, 
    isEmpty,
    isNonEmptyObject, 
    isNonEmptyArray, 
    shallowCopy, 
    sortStrings, 
} from "_helpers";

import { 
    Alert,
    Button, 
    Card, 
    Col, 
    Divider, 
    message, 
    Row, 
    Space, 
    Switch,
    Tooltip,
    Typography 
} from "antd";

import { ReactComponent as Check } from "_assets/check.svg";
import { ReactComponent as Cross } from "_assets/cross.svg";
import { ReactComponent as Edit } from "_assets/edit.svg";
import { ReactComponent as Info } from "_assets/info.svg";

export const SettingsSendingPreferences = ({ valuesHandler, alertIsPendingHandler, setRequestIsSubmitted, activeKey }) => {
    const [ currentRecipientEditingId, setCurrentRecipientEditingId ] = useState(-1);
    const [ currentCustomReportingEditingId, setCurrentCustomReportingEditingId ] = useState(-1);
    
    const [ currentLastRecipientId, setCurrentLastRecipientId ] = useState(0);
    const [ currentLastCustomReportingId, setCurrentLastCustomReportingId ] = useState(0);
    
    const [ reportingTypeCustomEnabled, setReportingTypeCustomEnabled ] = useState(false);

    const tmpRecipientInitValue = useMemo(() => (null), []);
    const [ tmpRecipient, setTmpRecipient ] = useState(tmpRecipientInitValue);
    const tmpCustomReportingInitValue = useMemo(() => ({}), []);
    const [ tmpCustomReporting, setTmpCustomReporting ] = useState(tmpCustomReportingInitValue);

    const { values, setValues } = valuesHandler;
    const { alertIsPending, setAlertIsPending } = alertIsPendingHandler;

    const [ initialValues, setInitialValues ] = useState(values); // TODO : A revoir

    const { Text } = Typography;
    const [ messageApi, contextHolder ] = message.useMessage();

    const { config } = useApp(),
        socsTranslate = config.cfg_socs;

    const periodicityOptions = useMemo(() => ([
        { label: 'Hebdomadaire', value: 'weekly' },
        { label: 'Mensuelle', value: 'monthly' },
        { label: 'Trimestrielle', value: 'quarterly'}
    ]), [])
    const iconDimensions = useMemo(() => ({
        width: 18,
        height: 18
    }), []);
    const { width, height } = iconDimensions;
    const recipientRegex = useMemo(() => ( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ ), []);
    const formatsTranslate = useMemo(() => ({
        report_pdf: 'Reporting (PDF)',
        report_xlsx: 'Reporting (XLSX)',
        ci_pdf: 'CI (PDF)',
        ci_xlsx: 'CI (XLSX)',
    }), [])
    const tooltipText = useMemo(() => (
        <>
           <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
                <div style={{ width: "45%", padding: "5px" }}>
                    Reporting "<b>Standard</b>" :<br /> Le reporting sera envoyé pour toutes les entités disponibles sur le scope, en un seul email selon les "<b>Formats</b>" définis et aux <b>Destinataires</b> spécifiés. Les destinataires seront listés dans l'ordre suivant : le premier en tant que destinataire principal, les suivants en copie.
                </div>
                <div style={{ width: "45%", padding: "5px" }}>
                    Reportings "<b>Personnalisés</b>" :<br /> Les reportings seront envoyés conformément à la configuration que vous aurez définie, incluant les entités, les formats et les destinataires.
                </div>
            </div>
        </>
    ), [])

    useEffect(() => {
        setReportingTypeCustomEnabled(( values?.customReporting && isNonEmpty(values.customReporting) ))
    }, [values])
    useEffect(() => {
        if( currentRecipientEditingId === -1 ){
            setTmpRecipient(tmpRecipientInitValue);
        }
    }, [currentRecipientEditingId])
    useEffect(() => {
        if( currentCustomReportingEditingId === -1 ){
            setTmpCustomReporting(tmpCustomReportingInitValue);
        }
    }, [currentCustomReportingEditingId])
    useEffect(() => {
        if( !arraysAreEquals(values?.formats, initialValues?.formats) ){
            let valuesCopy = shallowCopy(values);
            valuesCopy['formats'] = initialValues['formats'];
            setValues(valuesCopy);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeKey])
    useEffect(() => {
        setCurrentLastRecipientId(values?.['recipients'].length-1);
    }, [values?.['recipients'].length])
    useEffect(() => {
        setCurrentLastCustomReportingId(values?.['customReporting'].length-1);
    }, [values?.['customReporting'].length])

    const handlePeriodicityChange = ({ name, value }) => {
        if( alertIsPending ) return ;
        setValues(oldVal => ({ ...oldVal, [name]: value }));
        setRequestIsSubmitted(true);
    }

    const handleFormatsChange = useCallback(({ name, checked }) => {
        if( alertIsPending ) return ;
        if( currentRecipientEditingId !== -1 ){
            const isSaved = handleSaveRecipient(currentRecipientEditingId);
            if( !isSaved ) return ;
        } else if( currentCustomReportingEditingId !== -1 ){
            const isSaved = handleSaveCustomReporting(currentCustomReportingEditingId);
            if( !isSaved ) return;
        }

        let valuesCopy = shallowCopy(values);

        if( checked && !valuesCopy['formats'].includes(name) ){
            valuesCopy['formats'].push(name);
        } else if( !checked ){
            valuesCopy['formats'] = valuesCopy['formats'].filter(e => name !== e);
        }

        setValues(valuesCopy);
    }, [
        values, 
        currentRecipientEditingId, 
        currentCustomReportingEditingId,
        tmpRecipient,
        tmpCustomReporting,
        alertIsPending
    ])
    const handleSaveFormats = () => {
        if( alertIsPending ) return ;
        setInitialValues(values);
        setRequestIsSubmitted(true);
    }

    // const displayCustomError = useCallback((message) => messageApi.error(message), []); // Deprecated
    const displayCustomError = useCallback((message, duration = 3, key = 'errorMsg') => messageApi.open({
        key,
        type: 'error',
        content: message,
        duration
    }), []);
    const destroyCustomError = useCallback((key) => (messageApi.destroy(key)), []);

    const recipientElementIsValid = useCallback((elementToCheck) => {
        return recipientRegex.test(elementToCheck);
    }, [])
    const recipientIsValid = useCallback((id, elementToCheck = null) => {
        if( id === -1 ) return true;

        let valuesCopy = shallowCopy(values);

        if( elementToCheck === null ){
            elementToCheck = valuesCopy?.recipients[id];
        }
        elementToCheck = elementToCheck.replace(/\s+/g, "");

        if( !elementToCheck ){
            displayCustomError('L\'adresse e-mail n\'est pas renseignée');
            return false;
        } 

        if( recipientElementIsValid(elementToCheck) ){
            let alreadyExist = false;
            valuesCopy['recipients'].forEach((el, idx) => {
                if( alreadyExist === true ) return ;
                if( idx !== id && el.trim() === elementToCheck.trim() ){
                    alreadyExist = true;
                }
            })
            if( alreadyExist === true ){
                displayCustomError('Cette adresse e-mail est déjà répertoriée dans la liste des destinataires');
                return false;
            }
            return true;
        }
        displayCustomError('L\'adresse e-mail n\'est pas au format example@test.com');
        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values])
    
    const handleAddRecipient = useCallback(() => {
        if( alertIsPending || !recipientIsValid(currentLastRecipientId) ) return ;
        if( currentCustomReportingEditingId !== -1 ){
            const isSaved = handleSaveCustomReporting(currentCustomReportingEditingId);
            if( !isSaved ) return;
        }

        setCurrentRecipientEditingId(currentLastRecipientId+1);

        let valuesCopy = shallowCopy(values);
        const elToPush = '';

        valuesCopy['recipients'].push(elToPush);
        setValues(valuesCopy);
        setTmpRecipient(elToPush);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        values, 
        currentLastRecipientId, 
        currentCustomReportingEditingId, 
        tmpCustomReporting,
        alertIsPending
    ])
    const handleChangeRecipient = ( value ) => {
        if( alertIsPending ) return ;

        setTmpRecipient(value);
    }
    const handleSaveRecipient = useCallback(( id ) => {
        if( alertIsPending || !recipientIsValid(id, tmpRecipient) ) return false;

        let valuesCopy = shallowCopy(values);
        if( tmpRecipient.replace(/\s+/g, "") ){
            valuesCopy['recipients'][id] = tmpRecipient;
        }

        setValues(valuesCopy);
        setCurrentRecipientEditingId(-1);
        setRequestIsSubmitted(true);
        return true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, tmpRecipient, alertIsPending])
    const handleCancelRecipient = useCallback(( id ) => {
        if( alertIsPending ) return ;

        let valuesCopy = shallowCopy(values);
        let recipientToCancel = valuesCopy['recipients'][id];
        recipientToCancel = recipientToCancel.replace(/\s+/g, "");

        if( !recipientToCancel ){
            valuesCopy['recipients'] = valuesCopy['recipients'].filter((el, i) => id !== i);
        }

        setValues(valuesCopy);
        setCurrentRecipientEditingId(-1);
    }, [values, alertIsPending])
    const handleRemoveRecipient = useCallback(( id, loop = 0 ) => {
        if( alertIsPending || id === 0 ) return ;

        let valuesCopy = deepCopy(values);
        let customReportingIdsToDelete = [];

        if( isNonEmpty(valuesCopy['customReporting']) ){
            const recipientToDelete = valuesCopy['recipients'][id];
            valuesCopy['customReporting'].forEach((el, index) => {
                const { body } = el;

                if( body.recipients.includes(recipientToDelete) ){
                    body.recipients = body.recipients.filter(r => r !== recipientToDelete);
                }

                if( isEmpty(body.recipients) ){ // Demande de suppression du reporting personnalisés car plus aucun contact à qui l'envoyer
                    if( !customReportingIdsToDelete.includes(index) ) customReportingIdsToDelete.push(index);
                }
                
                return el;
            })

            if( isNonEmptyArray(customReportingIdsToDelete) && loop === 0 ){
                const multipleIds = customReportingIdsToDelete.length > 1;
                let dynamicContent = [];

                dynamicContent.push(multipleIds ? "Les Reportings " : "Le Reporting ");

                customReportingIdsToDelete.forEach((el, i) => {
                    if (i > 0) {
                        dynamicContent.push(", ");
                    }
                    dynamicContent.push(
                        <span key={i} style={{ fontWeight: "bold" }}>
                            #{el}
                        </span>
                    );
                });

                dynamicContent.push(multipleIds ? " vont être supprimés " : " va être supprimé ");
                dynamicContent.push(" suite à la suppression de ce destinataire ");

                displayCustomError(
                    <span style={{ verticalAlign: 'middle' }}>
                        { dynamicContent }
                        <Space className="ms-2">
                            <Button 
                                danger 
                                onClick={() => {
                                    destroyCustomError('removeCustomReporting');
                                    setAlertIsPending(false);
                                }}
                            >
                                Refuser
                            </Button>
                            <Button 
                                style={{ 
                                    background: 'green', 
                                    borderColor: 'green', 
                                    color: 'white' 
                                }}
                                onClick={() => {
                                    destroyCustomError('removeCustomReporting');
                                    setAlertIsPending(false);
                                    handleRemoveRecipient(id, loop+1);
                                }}
                            >
                                Accepter
                            </Button>
                        </Space>
                    </span>,
                    0,
                    'removeCustomReporting'
                )
                setAlertIsPending(true);
                return ;
            }
        } 

        // valuesCopy['recipients'].splice(id, 1);
        valuesCopy['recipients'] = valuesCopy['recipients'].filter((_,index) => id !== index);
        valuesCopy['customReporting'] = valuesCopy['customReporting'].filter((_, index) =>  !customReportingIdsToDelete.includes(index));
        setCurrentRecipientEditingId(-1);
        setValues(valuesCopy);
        setRequestIsSubmitted(true);
    }, [values, alertIsPending])

    const toggleReportingType = useCallback(( checked, removeElement = false ) => {
        if( alertIsPending ) return ;

        if( !checked && values.customReporting.length > 0 && !removeElement ){
            displayCustomError(
                <span style={{ verticalAlign: 'middle' }}>
                    <span>
                        Attention cette action est irréversible et va supprimer tous les <span style={{ fontWeight: "bold" }}>Reportings Personnalisés</span>
                    </span>
                    <Space className="ms-2">
                        <Button 
                            danger 
                            onClick={() => {
                                destroyCustomError('removeCustomReporting');
                                setAlertIsPending(false);
                            }}
                        >
                            Refuser
                        </Button>
                        <Button 
                            style={{ 
                                background: 'green', 
                                borderColor: 'green', 
                                color: 'white' 
                            }}
                            onClick={() => {
                                destroyCustomError('removeCustomReporting');
                                setAlertIsPending(false);
                                toggleReportingType(checked, true);
                            }}
                        >
                            Accepter
                        </Button>
                    </Space>
                </span>,
                0,
                'removeCustomReporting'
            )
            setAlertIsPending(true);
            return ;
        } else if( !checked && removeElement ){
            let valuesCopy = shallowCopy(values);
            valuesCopy['customReporting'] = [];
            setValues(valuesCopy);
            setReportingTypeCustomEnabled(checked);
            setRequestIsSubmitted(true);
            return ;
        }

        if( values.recipients.length > 0 ){
            setReportingTypeCustomEnabled(checked);
        } else if( checked === true ) {
            displayCustomError("Il faut ajouter au moins un destinataire avant de paramétrer les Reportings")
        }
    }, [values, alertIsPending])

    const customReportingElementIsValid = useCallback((elementToCheck) => {
        return elementToCheck?.['title'] && 
            elementToCheck?.['body'] && 
            Object.keys(elementToCheck['body']).length === 3 &&
            Object.values(elementToCheck['body']).every(e => isNonEmptyArray(e))
    }, [])
    const customReportingElementIsEmpty = useCallback((elementToCheck) => {
        return isNonEmptyObject(elementToCheck) 
            elementToCheck['title'].replace(/\s+/g, "") === "" && 
            isEmpty(elementToCheck['body']);
    }, [])
    const customReportingIsValid = useCallback((id, elementToCheck = null) => {
        if( id === -1 ) return true;

        let valuesCopy = shallowCopy(values);

        if( elementToCheck === null ){
            elementToCheck = valuesCopy?.customReporting[id];
        }

        if( customReportingElementIsValid(elementToCheck) ){
            let alreadyExist = false;
            valuesCopy['customReporting'].forEach((el, idx) => {
                if( alreadyExist === true ) return ;
                if( idx !== id && el.title.trim() === elementToCheck.title.trim() ){
                    alreadyExist = true;
                }
            })
            if( alreadyExist === true ){
                displayCustomError('Ce titre de Reporting existe déjà, veuillez le modifier');
                return false;
            }
            return true;
        }
        displayCustomError('Tous les champs du reporting personnalisé sont obligatoires et ne peuvent être vides');
        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values])

    const handleAddCustomReporting = useCallback(() => {
        let valuesCopy = shallowCopy(values);
        const elementToCheck = valuesCopy['customReporting'][currentLastCustomReportingId];

        if( alertIsPending ) return ;

        // Permet d'ajouter une nouvelle entrée sans sauvegarder par le bouton save mais par le bouton add pour ajouter plusieurs entrées à la suite 
        if( customReportingElementIsEmpty(elementToCheck) && !customReportingIsValid(currentLastCustomReportingId, tmpCustomReporting) ){ // Mode Création invalide
            return ;
        } else if( !customReportingElementIsEmpty(elementToCheck) && !customReportingIsValid(currentCustomReportingEditingId, tmpCustomReporting) ){ // Mode Edition invalide
            return ;
        } else if( customReportingElementIsEmpty(elementToCheck) && customReportingIsValid(currentLastCustomReportingId, tmpCustomReporting) ) { // Mode Création valide
            valuesCopy['customReporting'][currentLastCustomReportingId] = tmpCustomReporting;
            setValues(valuesCopy);
            setRequestIsSubmitted(true);
        } else if( !customReportingElementIsEmpty(elementToCheck) && customReportingIsValid(currentCustomReportingEditingId, tmpCustomReporting) ){ // Mode Edition valide
            valuesCopy['customReporting'][currentCustomReportingEditingId] = tmpCustomReporting;
            setValues(valuesCopy);
            setRequestIsSubmitted(true);
        }

        if( currentRecipientEditingId !== -1 ){
            const isSaved = handleSaveRecipient(currentRecipientEditingId);
            if( !isSaved ) return ;
        }

        let valuesCopy2 = shallowCopy(values);

        setCurrentCustomReportingEditingId(currentLastCustomReportingId+1);

        const objToPush = { title: '', body: {} };

        if( !arraysAreEquals(values?.formats, initialValues?.formats) ){
            valuesCopy2['formats'] = initialValues['formats'];
        }
     
        valuesCopy2['customReporting'].push(objToPush);
        setValues(valuesCopy2);
        setTmpCustomReporting(objToPush);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        values, 
        currentLastCustomReportingId, 
        currentRecipientEditingId,
        currentCustomReportingEditingId,
        tmpRecipient, 
        tmpCustomReporting,
        alertIsPending
    ])
    const handleChangeCustomReporting = ( name, value ) => {
        if( alertIsPending ) return ;

        let tmpCustomReportingCopy = deepCopy(tmpCustomReporting);

        let extendedName = null;
        const nameSplitted = name.split('_'),
            baseName = nameSplitted[0];
            
        if( baseName === 'title' ){
            tmpCustomReportingCopy[baseName] = value;
        } else if( baseName === 'body' ){
            extendedName = nameSplitted[1];
            if( !extendedName ) return ;
            if( !tmpCustomReportingCopy.hasOwnProperty(baseName) ) tmpCustomReportingCopy[baseName] = {}
            tmpCustomReportingCopy[baseName][extendedName] = value;
        }

        setTmpCustomReporting(tmpCustomReportingCopy);
    }
    const handleSaveCustomReporting = useCallback(( id ) => {
        if( alertIsPending || !customReportingIsValid(id, tmpCustomReporting) ) return false;

        let valuesCopy = shallowCopy(values);
        if( isNonEmptyObject(tmpCustomReporting) ){
            valuesCopy['customReporting'][id] = tmpCustomReporting;
        }

        setValues(valuesCopy);
        setCurrentCustomReportingEditingId(-1);
        setRequestIsSubmitted(true);
        return true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, tmpCustomReporting, alertIsPending])
    const handleEditCustomReporting = useCallback(( id ) => {
        if( alertIsPending || currentCustomReportingEditingId !== -1 ) return ;

        let customReportingCopy = shallowCopy(values?.customReporting[id]);
        setTmpCustomReporting(customReportingCopy);
        setCurrentCustomReportingEditingId(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, currentCustomReportingEditingId, alertIsPending])
    const handleCancelCustomReporting = useCallback(( id ) => {
        if( alertIsPending ) return ;

        let valuesCopy = shallowCopy(values);
        const elementToCancel = valuesCopy['customReporting'][id];

        if( elementToCancel['title'].replace(/\s+/g, "") == "" && isEmpty(elementToCancel['body']) ){ // Cas d'une création
            valuesCopy['customReporting'] = valuesCopy['customReporting'].filter((el, i) => id !== i); 
        }

        setValues(valuesCopy);
        setCurrentCustomReportingEditingId(-1);
    }, [tmpCustomReporting, alertIsPending])
    const handleRemoveCustomReporting = useCallback(( id ) => {
        if( alertIsPending ) return ;

        let valuesCopy = shallowCopy(values);
        
        // valuesCopy['customReporting'].splice(id, 1);
        valuesCopy['customReporting'] = valuesCopy['customReporting'].filter((_,index) => id !== index);
        setCurrentCustomReportingEditingId(-1);
        setValues(valuesCopy); // Synchro avec le Front
        setRequestIsSubmitted(true); // Envoi en BDD
    }, [values, alertIsPending])

    return (
        <>
            { contextHolder }
            <Space direction="vertical" className="w-100">
                <Input 
                    label="Périodicité"
                    type="select"
                    name="periodicity"
                    value={values.periodicity}
                    options={periodicityOptions}
                    onChange={handlePeriodicityChange}
                    style={{ width: '150px' }}
                />
                <Text>Formats :</Text>
                <Row gutter={[16,16]}>
                    <Col>
                        <Row justify="center" align="middle" className="flex-column">
                            <Text>Reporting</Text>
                            <Space>
                                <Input
                                    type="checkbox"
                                    label="PDF"
                                    name="report_pdf"
                                    checked={values.formats.includes('report_pdf')}
                                    onChange={handleFormatsChange}
                                />
                                <Input
                                    type="checkbox"
                                    label="XLSX"
                                    name="report_xlsx"
                                    checked={values.formats.includes('report_xlsx')}
                                    onChange={handleFormatsChange}
                                />
                            </Space>
                        </Row>
                    </Col>
                    <Col>
                        <Divider type="vertical" className="h-100" />
                    </Col>
                    <Col>
                        <Row justify="center" align="middle" className="flex-column">
                            <Text>Certificats d'irrécouvrabilité</Text>
                            <Space>
                                <Input
                                    type="checkbox"
                                    label="PDF"
                                    name="ci_pdf"
                                    checked={values.formats.includes('ci_pdf')}
                                    onChange={handleFormatsChange}
                                />
                                <Input
                                    type="checkbox"
                                    label="XLSX"
                                    name="ci_xlsx"
                                    checked={values.formats.includes('ci_xlsx')}
                                    onChange={handleFormatsChange}
                                />
                            </Space>
                        </Row>
                    </Col>
                    {
                        !arraysAreEquals(values?.formats, initialValues?.formats)
                        &&
                            <Col flex='32px'>
                                <Button 
                                    className="h-100" 
                                    icon={<Check height={height} width={width} />} 
                                    style={{ background: 'green', borderColor: 'green' }} 
                                    onClick={handleSaveFormats}
                                />
                            </Col>
                    }
                </Row>
                <Text>Destinataires :</Text>
                <ul className="mb-0">
                    {
                        values['recipients'].map((el, idx) => {
                            return (
                                <li key={idx} className="mb-1">
                                    <Row gutter={4}>
                                        {
                                            idx === 0 && (currentRecipientEditingId === -1 || currentRecipientEditingId!== idx)
                                            ?
                                            <>
                                                    <Col flex="338px">
                                                        <Text className="d-flex align-items-center h-100">{el}</Text>
                                                    </Col>
                                                </>
                                            :
                                            currentRecipientEditingId !== idx // Mode Edition off
                                            ?
                                                <>
                                                    <Col flex="338px">
                                                        <Text className="d-flex align-items-center h-100">{el}</Text>
                                                    </Col>
                                                    <Col flex='32px'>
                                                        <Button 
                                                            className="h-100" 
                                                            icon={<Cross height={height} width={width} />} 
                                                            style={{ background: 'red', borderColor: 'red' }} 
                                                            onClick={() => handleRemoveRecipient(idx) }
                                                        />
                                                    </Col>
                                                </>
                                            :
                                            currentRecipientEditingId !== -1 && currentRecipientEditingId === idx // Mode Edition on
                                            &&
                                                <>
                                                    <Col flex="302px">
                                                        <Input 
                                                            name={'recipient_'+idx}
                                                            value={tmpRecipient}
                                                            onChange={({ value }) => handleChangeRecipient(value)}
                                                        />
                                                    </Col>
                                                    <Col flex='32px'>
                                                        <Button 
                                                            className="h-100" 
                                                            icon={<Check height={height} width={width} />} 
                                                            style={{ background: 'green', borderColor: 'green' }} 
                                                            onClick={() => handleSaveRecipient(idx)}
                                                        />
                                                    </Col>
                                                    <Col flex='32px'>
                                                        <Button 
                                                            className="h-100" 
                                                            icon={<Cross height={height} width={width} />}
                                                            style={{ background: 'orange', borderColor: 'orange' }} 
                                                            onClick={() => handleCancelRecipient(idx)}
                                                        />
                                                    </Col>
                                                </>
                                        }
                                    </Row>
                                </li>
                            );
                        })
                    }
                </ul>
                <Button type="primary" style={{ width: '400px' }} onClick={handleAddRecipient}>Ajouter un destinataire</Button>

                <Text>
                    Type(s) de reporting(s)
                    <Tooltip placement="right" title={tooltipText} overlayStyle={{ maxWidth: '500px' }}>
                        <Info height={20} width={20} style={{ paddingBottom: '5px' }} />
                    </Tooltip>
                    :
                </Text>
                <Row style={{ margin: '5px 0px 15px 32px' }}>
                    <Space>
                        <Text>Reporting "Standard"</Text>
                        <Switch checked={reportingTypeCustomEnabled} onChange={( checked ) => toggleReportingType(checked)}></Switch>
                        <Text>Reportings "Personnalisés"</Text>
                    </Space>
                </Row>
                {
                    reportingTypeCustomEnabled &&
                    <>
                        <Row gutter={[8,16]}>
                            {
                                values['customReporting'].map((el, idx) => {
                                    const { title, body } = el;
                                    return (
                                        <Col xs={24} md={12} lg={8} key={idx}>
                                            <Row gutter={8} className="h-100">
                                                {
                                                    currentCustomReportingEditingId !== idx // Mode Edition off
                                                    ?
                                                        <>
                                                            <Col flex='1 1'>
                                                                <Card title={title} className="h-100">
                                                                    <Space direction='vertical' size={22} className='w-100'>
                                                                        {
                                                                            Object.entries(body)
                                                                                .sort(([aKey], [bKey]) => {
                                                                                    const order = ['entities', 'formats', 'recipients'];
                                                                                    const aIdx = order.findIndex(e => e === aKey);
                                                                                    const bIdx = order.findIndex(e => e === bKey);

                                                                                    if( aIdx < bIdx ) return -1;
                                                                                    if( aIdx > bIdx ) return 1;
                                                                                    return 0;
                                                                                })
                                                                                .map(([key, val]) => {
                                                                                    return (
                                                                                        <Row key={key}>
                                                                                            <Col xs={4}>
                                                                                                <Text>
                                                                                                    {
                                                                                                        key === 'entities'
                                                                                                        ?
                                                                                                            'Sur :'
                                                                                                        :
                                                                                                        key === 'formats'
                                                                                                        ?
                                                                                                            'En :'
                                                                                                        :
                                                                                                        key === 'recipients'
                                                                                                        &&
                                                                                                            'Pour :'
                                                                                                    }
                                                                                                </Text>
                                                                                            </Col>
                                                                                            <Col xs={20}>
                                                                                                {
                                                                                                    key === 'entities'
                                                                                                    ?
                                                                                                        val
                                                                                                            .map(el => socsTranslate[el])
                                                                                                            .join(' // ')
                                                                                                    :
                                                                                                    key === 'formats'
                                                                                                    ?
                                                                                                        val
                                                                                                            .map(el => formatsTranslate[el])
                                                                                                            .join(', ')
                                                                                                    
                                                                                                    :
                                                                                                        val.join(', ')
                                                                                                }
                                                                                            </Col>
                                                                                        </Row>
                                                                                    );
                                                                            })
                                                                        }
                                                                    </Space>
                                                                </Card>
                                                            </Col>
                                                            <Col flex='32px'>
                                                                <Button 
                                                                    className="h-100" 
                                                                    icon={<Edit height={height} width={width} />} 
                                                                    style={{ background: '#0b6fba', borderColor: '#0b6fba' }} 
                                                                    onClick={() => handleEditCustomReporting(idx) }
                                                                />
                                                            </Col>
                                                            <Col flex='32px'>
                                                                <Button 
                                                                    className="h-100" 
                                                                    icon={<Cross height={height} width={width} />} 
                                                                    style={{ background: 'red', borderColor: 'red' }} 
                                                                    onClick={() => handleRemoveCustomReporting(idx) }
                                                                />
                                                            </Col>
                                                        </>
                                                    :
                                                    currentCustomReportingEditingId !== -1 && currentCustomReportingEditingId === idx // Mode Edition on
                                                    &&
                                                        <>
                                                            <Col flex='1 1'>
                                                                <Card 
                                                                    title={
                                                                        <Row align='middle'>
                                                                            <Col xs={10}>
                                                                                <Text>Nom du reporting :</Text>
                                                                            </Col>
                                                                            <Col xs={14}>
                                                                                <Input
                                                                                    name={'title_' + idx}
                                                                                    value={tmpCustomReporting?.title}
                                                                                    onChange={({ name, value }) => handleChangeCustomReporting(name, value)}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                }
                                                                >
                                                                    <Space direction='vertical' className='w-100'>
                                                                        <Row align='middle'>
                                                                            <Col xs={10}>
                                                                                <Text>Entités :</Text>
                                                                            </Col>
                                                                            <Col xs={14}>
                                                                                <Input 
                                                                                    type='select'
                                                                                    name={'body_entities_' + idx}
                                                                                    className='w-100'
                                                                                    mode='multiple'
                                                                                    value={tmpCustomReporting?.body?.entities}
                                                                                    options={Object.entries(socsTranslate)
                                                                                        .sort(([aKey, aVal],[bKey, bVal]) => sortStrings(aVal,bVal))
                                                                                        .map(([key, val]) => {
                                                                                            return { label: val, value: key }
                                                                                        })}
                                                                                    onChange={({ name, value }) => handleChangeCustomReporting(name, value)}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row align='middle'>
                                                                            <Col xs={10}>
                                                                                <Text>Formats :</Text>
                                                                            </Col>
                                                                            <Col xs={14}>
                                                                                <Input 
                                                                                    type='select'
                                                                                    name={'body_formats_' + idx}
                                                                                    className='w-100'
                                                                                    mode='multiple'
                                                                                    value={tmpCustomReporting?.body?.formats}
                                                                                    options={initialValues.formats
                                                                                        .map(el => {
                                                                                            return { label: formatsTranslate[el], value: el }
                                                                                    })}
                                                                                    onChange={({ name, value }) => handleChangeCustomReporting(name, value)}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row align='middle'>
                                                                            <Col xs={10}>
                                                                                <Text>Destinataires :</Text>
                                                                            </Col>
                                                                            <Col xs={14}>
                                                                                <Input 
                                                                                    type='select'
                                                                                    name={'body_recipients_' + idx}
                                                                                    className='w-100'
                                                                                    mode='multiple'
                                                                                    value={tmpCustomReporting?.body?.recipients}
                                                                                    options={values.recipients
                                                                                        .filter(el => el)
                                                                                        .map(el => {
                                                                                            return { label: el, value: el }
                                                                                    })}
                                                                                    onChange={({ name, value }) => handleChangeCustomReporting(name, value)}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Space>
                                                                </Card>
                                                            </Col>
                                                            <Col flex='32px'>
                                                                <Button 
                                                                    className="h-100" 
                                                                    icon={<Check height={height} width={width} />} 
                                                                    style={{ background: 'green', borderColor: 'green' }} 
                                                                    onClick={() => handleSaveCustomReporting(idx)}
                                                                />
                                                            </Col>
                                                            <Col flex='32px'>
                                                                <Button 
                                                                    className="h-100" 
                                                                    icon={<Cross height={height} width={width} />} 
                                                                    style={{ background: 'orange', borderColor: 'orange' }} 
                                                                    onClick={() => handleCancelCustomReporting(idx) }
                                                                />
                                                            </Col>
                                                        </>
                                                }
                                            </Row>
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                        <Button type="primary" className="w-100" onClick={handleAddCustomReporting}>Créer nouveau reporting</Button>
                    </>
                }
            </Space>
        </>
    );
}